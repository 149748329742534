import React from 'react';
import StakedToken from '../types/stakedToken';
import StakingReward from '../types/stakingReward';
import TokenStakingData from '../types/tokenStakingData';
import { BigNumber } from 'ethers';

export type StakingContextValue = {
  status?: number;
  readStatus: () => void;
  stakedTokens?: StakedToken[];
  fetchStakedTokens: () => void;
  stakingReward?: StakingReward;
  fetchCurrentStakingReward: () => void;
  stakingDataByTokenIds?: Record<number, TokenStakingData>;
  readStakingDataByTokenIds: () => void;
  minimumHoneyConsumption?: BigNumber;
  readMinimumHoneyConsumption: () => void;
  stakingCooldown?: number;
  readStakingCooldown: () => void;
};

// @ts-ignore
const StakingContext = React.createContext<StakingContextValue>();

StakingContext.displayName = 'StakingContext';

export default StakingContext;
