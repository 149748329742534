import Countdown from 'react-countdown';
import EmptyState from '../components/EmptyState';
import RequestUnstakePopup from '../components/RequestUnstakePopup';
import StakedFancyBearTile from '../components/StakedFancyBearTile';
import StakedToken from '../types/stakedToken';
import UnstakePopup from '../components/UnstakePopup';
import useStaking from '../hooks/useStaking';
import useTranslate from '../hooks/useTranslate';
import { FaInfo } from 'react-icons/fa';
import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  VStack,
  useBoolean,
} from '@chakra-ui/react';

const StakedFancyBearsContainer = () => {
  const translate = useTranslate();
  const {
    stakedTokens,
    stakingReward,
    stakingDataByTokenIds,
    readStakingDataByTokenIds,
    stakingCooldown,
  } = useStaking();

  const [tokenId, setTokenId] = useState<number>();
  const [tick, setTick] = useState(0);

  const [isUnstakePopupOpen, setIsUnstakePopupOpen] = useBoolean();

  const [isRequestUnstakePopupOpen, setIsRequestUnstakePopupOpen] =
    useBoolean();

  const handleUnstake = useCallback(
    (tokenId: number) => {
      setTokenId(tokenId);
      setIsUnstakePopupOpen.on();
    },
    [setIsUnstakePopupOpen]
  );

  const handleRequestUnstake = useCallback(
    (tokenId: number) => {
      setTokenId(tokenId);
      setIsRequestUnstakePopupOpen.on();
    },
    [setIsRequestUnstakePopupOpen]
  );

  const handleRequestUnstakeSuccess = useCallback(() => {
    setIsRequestUnstakePopupOpen.off();
    readStakingDataByTokenIds();
  }, [setIsRequestUnstakePopupOpen, readStakingDataByTokenIds]);

  const handleComplete = useCallback(() => {
    setTick(tick + 1);
  }, [tick]);

  const renderTile = useCallback(
    (stakedToken: StakedToken) => {
      const stakingData = stakingDataByTokenIds?.[stakedToken.nftId];

      if (!stakingReward || !stakingData || !stakingCooldown) {
        return null;
      }

      const unstakeDate =
        stakingData.timestamp > 0 &&
        new Date((stakingData.timestamp + stakingCooldown) * 1000);

      const status: 'staking' | 'requestingUnstake' | 'readyToUnstake' =
        unstakeDate
          ? unstakeDate.getTime() > Date.now()
            ? 'requestingUnstake'
            : 'readyToUnstake'
          : 'staking';

      return (
        <StakedFancyBearTile
          key={stakedToken.nftId}
          stakedToken={stakedToken}
          stakingReward={stakingReward}
        >
          {status === 'staking' ? (
            <Button
              colorScheme="dark"
              size="sm"
              w="full"
              onClick={() => handleRequestUnstake(stakedToken.nftId)}
            >
              {translate('common:requestUnstake')}
            </Button>
          ) : status === 'readyToUnstake' ? (
            <Button
              size="sm"
              w="full"
              onClick={() => handleUnstake(stakedToken.nftId)}
            >
              {translate('common:unstake')}
            </Button>
          ) : (
            <HStack spacing="3">
              <Box
                bg="dark.800"
                w="full"
                borderRadius="lg"
                textAlign="center"
                lineHeight="10"
                fontSize="sm"
                fontWeight="semibold"
              >
                <Countdown
                  date={unstakeDate as Date}
                  onComplete={handleComplete}
                />
              </Box>

              <Popover>
                <PopoverTrigger>
                  <IconButton
                    aria-label="Info"
                    size="xs"
                    borderRadius="full"
                    colorScheme="dark"
                  >
                    <Icon as={FaInfo} />
                  </IconButton>
                </PopoverTrigger>
                <PopoverContent
                  bg="dark.900"
                  border="none"
                  borderRadius="lg"
                  textAlign="center"
                >
                  <PopoverBody fontSize="xs">
                    {translate('stakedFancyBears:cooldownHint')}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
          )}
        </StakedFancyBearTile>
      );
    },
    [
      translate,
      stakingReward,
      stakingDataByTokenIds,
      handleRequestUnstake,
      handleUnstake,
      handleComplete,
    ]
  );

  return (
    <>
      {stakingReward && stakedTokens?.length ? (
        <VStack w="full">{stakedTokens.map(renderTile)}</VStack>
      ) : (
        <Box py="12" w="full">
          <EmptyState
            opacity={0.6}
            label={translate('stakedFancyBears:empty')}
          />
        </Box>
      )}

      {isUnstakePopupOpen && tokenId && (
        <UnstakePopup
          tokenId={tokenId}
          isOpen
          onClose={setIsUnstakePopupOpen.off}
        />
      )}

      {isRequestUnstakePopupOpen && tokenId && (
        <RequestUnstakePopup
          tokenId={tokenId}
          isOpen
          onClose={setIsRequestUnstakePopupOpen.off}
          onSuccess={handleRequestUnstakeSuccess}
        />
      )}
    </>
  );
};

export default StakedFancyBearsContainer;
