import fancyBearsABI from '../abis/fancyBears.abi.json';
import fancyBearStakingABI from '../abis/fancyBearStaking.abi.json';
import fancyBearStakingRewardABI from '../abis/fancyBearStakingReward.abi.json';
import honeyTokenABI from '../abis/honeyToken.abi.json';
import levelsABI from '../abis/levels.abi.json';
import sepolia from './chains/sepolia';
import { chain } from 'wagmi';

const isTestnet = parseInt(process.env.REACT_APP_USE_TESTNET!, 10) === 1;

const config = {
  environment: process.env.NODE_ENV as 'production' | 'development',
  isTestnet,

  sentry: {
    sentryDsn: process.env.REACT_APP_SENTRY_DSN!,
  },

  ga: {
    gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID!,
  },

  infura: {
    infuraId: process.env.REACT_APP_INFURA_ID!,
    rpcUrl: process.env.REACT_APP_INFURA_RPC_URL!,
  },

  chains: isTestnet ? [sepolia] : [chain.mainnet],

  contracts: {
    fancyBearsContract: {
      address: process.env.REACT_APP_FANCY_BEARS_CONTRACT_ADDRESS!,
      interface: fancyBearsABI,
    },
    fancyBearStakingContract: {
      address: process.env.REACT_APP_FANCY_BEAR_STAKING_CONTRACT_ADDRESS!,
      interface: fancyBearStakingABI,
    },
    fancyBearStakingRewardContract: {
      address:
        process.env.REACT_APP_FANCY_BEAR_STAKING_REWARD_CONTRACT_ADDRESS!,
      interface: fancyBearStakingRewardABI,
    },
    honeyTokenContract: {
      address: process.env.REACT_APP_HONEY_TOKEN_CONTRACT_ADDRESS!,
      interface: honeyTokenABI,
    },
    levelsContract: {
      address: process.env.REACT_APP_LEVELS_CONTRACT_ADDRESS!,
      interface: levelsABI,
    },
  },

  urls: {
    fancyBearsImagesUrl: process.env.REACT_APP_FANCY_BEAR_IMAGES_URL!,
    fancyBearsCollectionUrl: process.env.REACT_APP_FANCY_BEARS_COLLECTION_URL!,
    publicUrl: process.env.PUBLIC_URL!,
    apiUrl: process.env.REACT_APP_API_URL!,
    buyEthUrl: process.env.REACT_APP_BUY_ETH_URL!,
    buyHoneyUrl: process.env.REACT_APP_BUY_HONEY_URL!,
    learnMoreUrl: process.env.REACT_APP_LEARN_MORE_URL!,
    discordUrl: process.env.REACT_APP_DISCORD_URL!,
    twitterUrl: process.env.REACT_APP_TWITTER_URL!,
  },
};

export default config;
