import config from '../constants/config';
import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';

const useStakingCooldown = (): [cooldown: number, readCooldown: () => void] => {
  const { data, refetch } = useContractRead({
    addressOrName: config.contracts.fancyBearStakingContract.address,
    contractInterface: config.contracts.fancyBearStakingContract.interface,
    functionName: 'cooldown',
  });

  return [(data as any as BigNumber)?.toNumber(), refetch];
};

export default useStakingCooldown;
