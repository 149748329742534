import Loading from '../components/Loading';
import useFetchCurrentStakingReward from '../hooks/useFetchCurrentStakingReward';
import useMinimumHoneyConsumption from '../hooks/useMinimumHoneyConsumption';
import useStakedTokensByWallet from '../hooks/useFetchStakedTokensByWallet';
import useStakingCooldown from '../hooks/useStakingCooldown';
import useStakingDataByTokenIds from '../hooks/useStakingDataByTokenIds';
import useStakingStatus from '../hooks/useStakingStatus';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useAccount } from 'wagmi';
import StakingContext, {
  StakingContextValue,
} from '../contexts/StakingContext';

type StakingProviderProps = {
  children: JSX.Element;
};

const StakingProvider: FunctionComponent<StakingProviderProps> = ({
  children,
}) => {
  const { address } = useAccount();

  const [status, readStatus] = useStakingStatus();
  const [minimumHoneyConsumption, readMinimumHoneyConsumption] =
    useMinimumHoneyConsumption();

  const [stakedTokens, fetchStakedTokensPromise] =
    useStakedTokensByWallet(address);

  const [stakingDataByTokenIds, readStakingDataByTokenIds] =
    useStakingDataByTokenIds(stakedTokens?.map(({ nftId }) => nftId));

  const [stakingCooldown, readStakingCooldown] = useStakingCooldown();

  const fetchStakedTokens = useCallback(() => {
    if (address) {
      fetchStakedTokensPromise(address);
    }
  }, [fetchStakedTokensPromise, address]);

  const [stakingReward, fetchStakingRewardPromise] =
    useFetchCurrentStakingReward();

  const fetchCurrentStakingReward = useCallback(
    () => fetchStakingRewardPromise(),
    [fetchStakingRewardPromise]
  );

  const isLoading =
    status === undefined ||
    stakedTokens === undefined ||
    stakingReward === undefined ||
    minimumHoneyConsumption === undefined;

  const contextValue = useMemo<StakingContextValue>(
    () => ({
      status,
      readStatus,
      stakedTokens,
      fetchStakedTokens,
      stakingReward,
      fetchCurrentStakingReward,
      stakingDataByTokenIds,
      readStakingDataByTokenIds,
      minimumHoneyConsumption,
      readMinimumHoneyConsumption,
      stakingCooldown,
      readStakingCooldown,
    }),
    [
      status,
      readStatus,
      stakedTokens,
      fetchStakedTokens,
      stakingReward,
      fetchCurrentStakingReward,
      stakingDataByTokenIds,
      readStakingDataByTokenIds,
      minimumHoneyConsumption,
      readMinimumHoneyConsumption,
      stakingCooldown,
      readStakingCooldown,
    ]
  );

  return (
    <StakingContext.Provider value={contextValue}>
      {isLoading ? <Loading /> : children}
    </StakingContext.Provider>
  );
};

export default StakingProvider;
